<template>
  <b-modal
    id="modal-registro-incidente"
    size="md"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    v-model="mostrarModal"
    dialog-class="modal-dialog-scrollable"
    v-if="mostrarModal"
    @close="handleModalClose"
  >
    <template slot="modal-title">
      <div>Reportar Incidente</div>
    </template>

    <template slot="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        @click.prevent="handleModalClose()"
      >
        <i class="fa fa-ban"></i> Cancelar
      </button>
      <button
        type="button"
        class="btn btn-danger"
        @click.prevent="saveIncidente()"
      >
        <i class="fa fa-save"></i> Guardar
      </button>
    </template>

    <ul
      class="nav nav-pills nav-danger row row-paddingless m-0 p-0"
      role="tablist"
    >
      <!--begin::Item-->
      <li
        class="nav-item d-flex col flex-grow-1 flex-shrink-0 mr-3 mb-3 mb-lg-0"
      >
        <a
          class="nav-link border d-flex flex-grow-1 rounded flex-column align-items-center cursor-pointer"
          @click.prevent="tipoIncidente = 'acto-violencia'"
          :class="[tipoIncidente == 'acto-violencia' ? 'active' : '']"
        >
          <span class="nav-icon py-2 w-auto">
            <i
              class="fa fa-hand-paper fa-2x"
              :class="[tipoIncidente == 'acto-violencia' ? 'text-white' : '']"
            >
            </i>
          </span>
          <span class="nav-text font-size-lg py-2 font-weight-bold text-center"
            ><small>20)</small> Acto de Violencia</span
          >
        </a>
      </li>
      <!--end::Item-->
      <!--begin::Item-->
      <li
        class="nav-item d-flex col flex-grow-1 flex-shrink-0 mr-0 mb-3 mb-lg-0"
      >
        <a
          class="nav-link border d-flex flex-grow-1 rounded flex-column align-items-center cursor-pointer"
          @click.prevent="tipoIncidente = 'otros'"
          :class="[tipoIncidente == 'otros' ? 'active' : '']"
        >
          <span class="nav-icon py-2 w-auto">
            <i
              class="fa fa-bell fa-2x"
              :class="[tipoIncidente == 'otros' ? 'text-white' : '']"
            >
            </i>
          </span>
          <span
            class="nav-text font-size-lg py-2 font-weight-bolder text-center"
            ><small>21)</small> Otros</span
          >
        </a>
      </li>
      <!--end::Item-->
    </ul>

    <div v-if="tipoIncidente == 'acto-violencia'">
      <div class="separator separator-solid my-5"></div>
      <form id="frm-modal-registro-acto-violencia">
        <div class="form-group row">
          <label class="col-12"> Hora del evento:</label>
          <div class="col-12">
            <b-form-timepicker
              v-model="actoViolencia.HoraEvento"
              now-button
              :reset-button="false"
              minutes-step="15"
              v-bind="timePickerLocal"
              required
            ></b-form-timepicker>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-12">
            Describa brevemente el acto de violencia:</label
          >
          <div class="col-12">
            <textarea
              v-model="actoViolencia.Descripcion"
              class="form-control"
              type="text"
              placeholder=""
              required
            >
            </textarea>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-12">
            Describa brevemente las medidas tomadas:</label
          >
          <div class="col-12">
            <textarea
              v-model="actoViolencia.MedidasTomadas"
              class="form-control"
              type="text"
              placeholder=""
              required
            >
            </textarea>
          </div>
        </div>
        <hr />
        <file-pond
          name="testActoViolencia"
          ref="pondActoViolencia"
          label-idle="Arrastra imágenes o <u>haz clic aquí</u><br><i class='fa fa-image'></i>"
          :allow-multiple="true"
          accepted-file-types="image/jpeg, image/png, image/gif, image/jpg"
          :files="filePondActoViolenciaImages"
        />
      </form>
    </div>

    <div v-if="tipoIncidente == 'otros'">
      <div class="separator separator-solid my-5"></div>
      <form id="frm-modal-registro-otros">
        <div class="form-group row">
          <label class="col-12"> Hora del evento:</label>
          <div class="col-12">
            <b-form-timepicker
              v-model="otros.HoraEvento"
              now-button
              :reset-button="false"
              minutes-step="15"
              v-bind="timePickerLocal"
              required
            ></b-form-timepicker>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-12">Número de mesa:</label>
          <div class="col-12">
            <input
              v-model="otros.NumeroMesa"
              class="form-control"
              type="text"
              placeholder=""
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-12"> Marque el caso que identifica:</label>
          <div class="col-12">
            <table class="table mb-0">
              <tbody>
                <tr v-if="!casos">
                  <td><i>Cargando casos...</i></td>
                </tr>
                <tr v-else v-for="caso in casos" :key="caso.parametroId">
                  <td class="px-0">
                    <label
                      class="radio radio-danger radio-lg"
                      v-if="caso.codigo != 'IN_10'"
                    >
                      <input
                        type="radio"
                        v-model="otros.TipoIncidenteParamId"
                        :value="caso.parametroId"
                      />
                      <span class="mr-2"></span>{{ caso.descripcion }}</label
                    >
                    <label
                      class="radio radio-danger radio-lg"
                      v-if="caso.codigo == 'IN_10'"
                      v-b-tooltip.hover.v-dark
                      title="Recuerde describir el incidente"
                    >
                      <input
                        type="radio"
                        v-model="otros.TipoIncidenteParamId"
                        :value="caso.parametroId"
                      />
                      <span class="mr-2"></span>{{ caso.descripcion }}</label
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-12"> Indique si intervino alguna autoridad:</label>
          <div class="col-12">
            <input
              v-model="otros.AutoridadIntervencion"
              class="form-control"
              type="text"
              placeholder=""
              required
            />
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-12"> Describa el incidente:</label>
          <div class="col-12">
            <textarea
              v-model="otros.Descripcion"
              class="form-control"
              type="text"
              placeholder=""
              ref="contentTextArea"
              id="contentTextArea"
              required
            >
            </textarea>
          </div>
        </div>
        <hr />
        <file-pond
          name="testIncidente"
          ref="pondIncidente"
          label-idle="Arrastra imágenes o <u>haz clic aquí</u><br><i class='fa fa-image'></i>"
          :allow-multiple="true"
          accepted-file-types="image/jpeg, image/png, image/gif, image/jpg"
          :files="filePondIncidenteImages"
        />
      </form>
    </div>
  </b-modal>
</template>

<style></style>

<script>
import moment from "moment";
import Vue from "vue";

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately
// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: "modal-registro-incidente",
  components: {
    FilePond,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    tipoIncidente: {
      type: String,
    },
    localVotacionId: {
      type: Number,
    },
    procesoElectoralId: {
      type: Number,
    },
    actoViolenciaRegistradoCallback: {},
    incidenteRegistradoCallback: {},
  },
  data: () => ({
    mostrarModal: true,
    filePondActoViolenciaImages: [],
    filePondIncidenteImages: [],
    casos: [],
    actoViolencia: {
      HoraEvento: null,
      Descripcion: null,
      MedidasTomadas: null,
      imagenes: [],
    },
    otros: {
      HoraEvento: null,
      TipoIncidenteParamId: null,
      NumeroMesa: null,
      AutoridadIntervencion: null,
      Descripcion: null,
      imagenes: [],
    },
    timePickerLocal: {
      labelHours: "Hora",
      labelMinutes: "Minutos",
      labelSeconds: "Segundos",
      labelIncrement: "Subir",
      labelDecrement: "Bajar",
      labelSelected: "Hora seleccionada",
      labelNoTimeSelected: "Seleccionar hora",
      labelNowButton: "Ahora",
      labelResetButton: "Borrar",
      labelCloseButton: "Cerrar",
    },
  }),
  computed: {},
  methods: {
    handleModalClose: async function(event) {
      if (event) {
        event.preventDefault();
      }
      var resp = await this.sweetConfirm("¿Desea cerrar esta ventana?");
      if (resp) {
        this.mostrarModal = false;
      }
    },
    saveActoViolencia: async function() {
      if (!this.isFormValid("frm-modal-registro-acto-violencia")) {
        return this.sweetAlert(
          "Hay campos incompletos o con datos incorrectos."
        );
      }

      if (!(await this.sweetConfirm("¿Desea registrar este incidente?"))) {
        return;
      }

      var model = {
        ...this.actoViolencia,
        LocalVotacionId: this.localVotacionId,
        ProcesoElectoralId: this.procesoElectoralId,
      };

      var formData = this.convertModelToFormData(model);

      var archivos = this.$refs.pondActoViolencia.getFiles();

      for (let i = 0; i < archivos.length; i++) {
        formData.append("imagenes", archivos[i].file);
      }

      this.showLoading();
      this.$http
        .post(this.apiRoute("Sheet", "AddEditFichaActosViolencia"), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.sweetAlert(response.body.message, "success");
            // this.$notifyHub.notificarIncidenteRegistrado(
            //   this.procesoElectoralId,
            //   this.localVotacionId,
            //   "ACTO",
            //   response.body.data.localVotacionFichaActosViolenciaId
            // );
            if (this.actoViolenciaRegistradoCallback)
              this.actoViolenciaRegistradoCallback(response.body.data);
          }
        });
      this.mostrarModal = false;
    },
    saveOtros: async function() {
      if (!this.isFormValid("frm-modal-registro-otros")) {
        return this.sweetAlert(
          "Hay campos incompletos o con datos incorrectos."
        );
      }

      if (!this.otros.TipoIncidenteParamId) {
        return this.sweetAlert("Debe seleccionar el caso identificado.");
      }

      if (!(await this.sweetConfirm("¿Desea registrar este incidente?"))) {
        return;
      }

      var model = {
        ...this.otros,
        LocalVotacionId: this.localVotacionId,
        ProcesoElectoralId: this.procesoElectoralId,
      };

      var formData = this.convertModelToFormData(model);

      var archivos = this.$refs.pondIncidente.getFiles();

      for (let i = 0; i < archivos.length; i++) {
        formData.append("imagenes", archivos[i].file);
      }

      this.showLoading();
      this.$http
        .post(this.apiRoute("Sheet", "AddEditFichaIncidentesOtro"), formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function(response) {
          if (!this.procesarRespuestaErrorDefecto(response)) {
            this.sweetAlert(response.body.message, "success");
            // this.$notifyHub.notificarIncidenteRegistrado(
            //   this.procesoElectoralId,
            //   this.localVotacionId,
            //   "OTROS",
            //   response.body.data.localVotacionFichaIncidenteId
            // );
            if (this.incidenteRegistradoCallback)
              this.incidenteRegistradoCallback(response.body.data);
          }
        });
      this.mostrarModal = false;
    },
    saveIncidente: async function() {
      switch (this.tipoIncidente) {
        case "acto-violencia":
          this.saveActoViolencia();
          break;
        case "otros":
          this.saveOtros();
          break;
      }
    },
    fetchTipoIncidentes: function() {
      this.$http
        .get(this.apiRoute("Parameter", "ListParametros"), {
          params: {
            Code: "TIPO_INCIDENTE",
          },
        })
        .then(function(response) {
          this.casos = response.body.data;
        });
    },
  },
  watch: {
    mostrarModal: function(newValue) {
      this.$emit("input", newValue);
    },
    "otros.TipoIncidenteParamId": function() {
      this.$nextTick(() => {
        this.$refs.contentTextArea.focus();
      });
    },
  },
  mounted: function() {
    let fechaActual = moment().format("HH:mm:00");
    this.actoViolencia.HoraEvento = fechaActual;
    this.otros.HoraEvento = fechaActual;
    this.mostrarModal = this.value;
    this.initParsley();
    this.fetchTipoIncidentes();
  },
  destoyed: function() {},
};
</script>
