<template>
  <div>
    <fab :position="'bottom-right'" :bg-color="'#e71c1c'" :icon-size="'small'" :fixed-tooltip="true" :main-icon="'warning'" :main-tooltip="'Reportar incidente'" :actions="fabActions" @reporteActoViolencia="reporteActoViolencia" @reporteOtros="reporteOtros"></fab>

    <div v-if="mostrarModal">
      <modal-registro-incidente
        v-model="mostrarModal"
        :local-votacion-id="localVotacionId"
        :proceso-electoral-id="procesoElectoralId"
        :tipo-incidente="tipoIncidente"
        :incidente-registrado-callback="handleIncidenteSaved"
        :acto-violencia-registrado-callback="handleActosViolenciaSaved"
      ></modal-registro-incidente>
    </div>
  </div>
</template>

<style></style>

<script>
import fab from "vue-fab";
import modalRegistroIncidente from "@/components/incident/modal-registro-incidente";

export default {
  name: "fab-registro-incidente",
  components: {
    fab,
    modalRegistroIncidente,
  },
  props: {
    localVotacionId: {
      type: Number,
    },
    procesoElectoralId: {
      type: Number,
    },
  },
  data: () => ({
    tipoIncidente: "acto-violencia",
    mostrarModal: false,
    fabActions: [
      {
        name: "reporteActoViolencia",
        icon: "pan_tool",
        tooltip: "Acto de Violencia",
        //color:''
      },
      {
        name: "reporteOtros",
        icon: "notifications",
        tooltip: "Otros",
        //color:''
      },
    ],
  }),
  computed: {},
  methods: {
    reporteActoViolencia() {
      this.tipoIncidente = "acto-violencia";
      this.mostrarModal = true;
    },
    reporteOtros() {
      this.tipoIncidente = "otros";
      this.mostrarModal = true;
    },
    handleIncidenteSaved: function(data) {
      this.$emit("incidente-registrado", data);
    },
    handleActosViolenciaSaved: function(data) {
      this.$emit("acto-violencia-registrado", data);
    },
  },
  watch: {},
  mounted: function() {
    this.initParsley();
  },
  destoyed: function() {},
};
</script>
